import { useState } from "react";
import "../App.css";

export default function HeroImage({imageSrc, overlay = null}) {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="Hero-Image-Container ">
      <img src={imageSrc} className='Hero-Image' alt="hero" onLoad={() => setLoaded(true)} />
      {loaded && overlay}
    </div>
  )
}