import "../App.css";
import constants from '../constants.json';
import HeaderLink from "./HeaderLink";
import { useNavigate } from 'react-router-dom';
import { rhgStacked1 } from '../assets/logos/LogosDictionary';



export default function Header(props) {
  const navigate = useNavigate();

  /*  Functional sticky nav bar for non-responsive views
  window.onscroll = function() {
  //   applyStickyHeader()
  };

  function applyStickyHeader() {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    console.log("on scroll ", sticky);

    if (window.pageYOffset > constants.STICKY_HEADER_Y_OFFST) {
      header.classList.add("Sticky");
    } else {
      header.classList.remove("Sticky");
    }
  }
  */


  function logoClickHandler() {
    navigate(constants.HOME_PAGE_ROUTE)
  }

  const navLinks = (
    <>
      <HeaderLink text="Home" link={constants.HOME_PAGE_ROUTE} />
      <HeaderLink text="Locations" link={constants.LOCATIONS_PAGE_ROUTE} />
      <HeaderLink text="Logos" link={constants.LOGOS_PAGE_ROUTE} />
      <HeaderLink text="Careers" link={constants.CAREERS_PAGE_ROUTE} />
    </>
  )

  const hamburgerMenu = (
    <>
      <input id="Menu-Toggle" type="checkbox" />
      <label className='Menu-Button-Container' htmlFor="Menu-Toggle">
        <div className='Menu-Button' />
       </label>
      <ul className="Menu">
        {navLinks}
      </ul>
    </>
  )

  return (
    <div className="Header" id="header">
      <img src={rhgStacked1} alt="logo" className="Header-Logo" onClick={logoClickHandler} />
      {navLinks}
      {hamburgerMenu}
    </div>
  )
}