import constants from '../constants.json';

const allRestaurants = [
  `${constants.S3_URI}assets/restaurants/wide_restaurant_interior_2.png`,
  `${constants.S3_URI}assets/restaurants/rooftop_bar.png`,
  `${constants.S3_URI}assets/restaurants/rooftop_bar_2.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior_2.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior_4.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior_5.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior_6.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant_3.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant_4.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant_2.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior_3.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior_7.png`,
  `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant.png`
];

const allLogos = [
  `${constants.S3_URI}assets/logos/backwards_rhg_no_border.svg`,
  `${constants.S3_URI}assets/logos/backwards_rhg_white_border.svg`,
  `${constants.S3_URI}assets/logos/rh_square.svg`,
  `${constants.S3_URI}assets/logos/rh_white_border.svg`,
  `${constants.S3_URI}assets/logos/rh.svg`,
  `${constants.S3_URI}assets/logos/rhg_text1.svg`,
  `${constants.S3_URI}assets/logos/rhg_text2.svg`,
  `${constants.S3_URI}assets/logos/rhg_text3.svg`,
  `${constants.S3_URI}assets/logos/rhg_cursive_text.svg`,
  `${constants.S3_URI}assets/logos/rhg_stacked1.svg`,
  `${constants.S3_URI}assets/logos/rhg_stacked2.svg`,
  `${constants.S3_URI}assets/logos/rhg_white_border_variant.svg`,
  `${constants.S3_URI}assets/logos/rhg_white_border.svg`
]

const allPeople = [
  `${constants.S3_URI}assets/people/hayley_upper_body.png`
]

const AllImageSources = [...allRestaurants, ...allLogos, ...allPeople];

export default AllImageSources;