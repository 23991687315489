import HeroImage from "../../components/HeroImage";
import Body from "../Body";
import constants from "../../constants.json";

export default function Locations(props) {
  const image = `${constants.S3_URI}assets/restaurants/wide_restaurant_interior_2.png`;
  const roofBar = `${constants.S3_URI}assets/restaurants/rooftop_bar.png`;
  const roofBar2 = `${constants.S3_URI}assets/restaurants/rooftop_bar_2.png`;
  const restaurant1 = `${constants.S3_URI}assets/restaurants/restaurant_interior_2.png`;
  const restaurant2 = `${constants.S3_URI}assets/restaurants/restaurant_interior_4.png`;
  const restaurant3 = `${constants.S3_URI}assets/restaurants/restaurant_interior_5.png`;
  const restaurant6 = `${constants.S3_URI}assets/restaurants/restaurant_interior_6.png`;
  const restaurant4 = `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant_3.png`;
  const restaurant5 = `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant_4.png`;
  const restaurant7 = `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant_2.png`;
  const restaurant8 = `${constants.S3_URI}assets/restaurants/restaurant_interior.png`;
  const restaurant9 = `${constants.S3_URI}assets/restaurants/restaurant_interior_3.png`;
  const restaurant10 = `${constants.S3_URI}assets/restaurants/restaurant_interior_7.png`;
  const restaurant11 = `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant.png`;

  function toggleOpacity(visible, event) {
    event.preventDefault();
    if (visible) {
      event.target.classList.remove('Opacity-Hidden')
      event.target.classList.add('Opacity-Shown')
    } else {
      event.target.classList.remove('Opacity-Shown');
      event.target.classList.add('Opacity-Hidden')
    }
  }

  return (
    <>
      <HeroImage imageSrc={image} />
      <Body>
        <div className="Grid"> 
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>San Francisco</p>
            </div> 
            <img src={roofBar2} alt="location1"/>
          </div>
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>New York</p>
            </div> 
            <img src={restaurant1} alt="location2"/>
          </div>
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Mountain View</p>
            </div> 
            <img src={restaurant10} alt="location3"/>
          </div>
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Miami</p>
            </div> 
            <img src={restaurant4} alt="location4"/>
          </div>
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Chicago</p>
            </div> 
            <img src={roofBar} alt="location5"/>
          </div>
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Austin</p>
            </div> 
            <img src={restaurant11} alt="location6"/>
          </div>
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Denver</p>
            </div> 
            <img src={restaurant2} alt="location7"/>
          </div>
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Seattle</p>
            </div> 
            <img src={restaurant9} alt="location8"/>
          </div>
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Nashville</p>
            </div> 
            <img src={restaurant8} alt="location9"/>
          </div>
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Tampa</p>
            </div> 
            <img src={restaurant7} alt="location10"/>
          </div>
          <div className="Grid-Square">
          <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Napa</p>
            </div> 
            <img src={restaurant4} alt="location11"/>
          </div>
          <div className="Grid-Square">
          <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>San Diego</p>
            </div> 
            <img src={restaurant6} alt="location12"/>
          </div>
          <div className="Grid-Square">
          <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Boston</p>
            </div> 
            <img src={restaurant5} alt="location13"/>
          </div>
          <div className="Grid-Square">
            <div className="Grid-Hover-Text" onMouseEnter={(event) => toggleOpacity(true, event)} onMouseLeave={(event) => toggleOpacity(false, event)}>
              <p>Albuquerque</p>
            </div> 
            <img src={restaurant3} alt="location14"/>
          </div>
        </div>
      </Body>
    </>
  )
}