import constants from "../../constants.json";
import HeroImage from "../../components/HeroImage";
import Body from "../Body";
import {  
  rhgNoBorder,
  rhgBorder,
  rhSquare,
  rhBorder,
  rhgText1,
  rhgText2,
  rhgText3,
  rhgText4,
  rhgStacked1,
  rhgStacked2,
  rhgWhiteBorder1,
  rhgWhiteBorder2,
  rh
} from "../../assets/logos/LogosDictionary";

export default function Logos(props) {
  const heroImage = `${constants.S3_URI}assets/restaurants/wide_rooftop_bar.png`;

  return (
    <div>
      <HeroImage imageSrc={heroImage} overlay={null} />
      <Body>
        <div className="Grid"> 
          <div class="Grid-Square">
            <img src={rhgStacked1} alt="logo1" />
          </div>
          <div class="Grid-Square">
            <img src={rhBorder} alt="logo2"/>
          </div>
          <div class="Grid-Square">
            <img src={rhgBorder} alt="logo3"/>
          </div>
          <div className="Grid-Square">
            <img src={rh}alt="logo4"/>
          </div>
          <div class="Grid-Square">
            <img src={rhgText1} alt="logo5"/>
          </div>
          <div class="Grid-Square">
            <img src={rhgWhiteBorder1} alt="logo6"/>
          </div>
          <div class="Grid-Square">
            <img src={rhgNoBorder} alt="logo7"/>
          </div>
          <div class="Grid-Square">
            <img src={rhgText2} alt="logo8"/>
          </div>
          <div class="Grid-Square">
            <img src={rhSquare} alt="logo9"/>
          </div>
          <div class="Grid-Square">
            <img src={rhgStacked2} alt="logo10"/>
          </div>
          <div class="Grid-Square">
            <img src={rhgText3} alt="logo11"/>
          </div>
          <div class="Grid-Square">
            <img src={rhgWhiteBorder2} alt="logo12"/>
          </div>
          <div class="Grid-Square">
            <img src={rhgText4} alt="logo13"/>
          </div>
        </div>
      </Body>
    </div>
  )
}