import '../../App.css';
import HeroImage from '../../components/HeroImage';
import Section from '../../components/Section';
import Body from '../Body';
import constants from '../../constants.json';

function HomePage() {
  const roofBar = `${constants.S3_URI}assets/restaurants/rooftop_bar.png`;
  const restaurant1 = `${constants.S3_URI}assets/restaurants/restaurant_interior_2.png`;
  const restaurant2 = `${constants.S3_URI}assets/restaurants/restaurant_interior_4.png`;
  const restaurant3 = `${constants.S3_URI}assets/restaurants/restaurant_interior_5.png`;
  const restaurant6 = `${constants.S3_URI}assets/restaurants/restaurant_interior_6.png`;
  const restaurant4 = `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant_3.png`;
  const restaurant5 = `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant_4.png`;
  const restaurant7 = `${constants.S3_URI}assets/restaurants/restaurant_interior_bar_restaurant_2.png`;
  const image = `${constants.S3_URI}assets/restaurants/wide_restaurant_interior_4.png`;
  const hayley = `${constants.S3_URI}assets/people/hayley_upper_body.png`;

  // const overlayText = "Roemers Hospitality Group";
  // const overlay = (
  //   <div className='Home-Page-Overlay'>
  //     <p style={{ margin: "8px"}}>
  //       {overlayText}
  //       </p>
  //   </div>
  // )

  return (
    <>
      <HeroImage imageSrc={image} /> 
      <Body>
        {/* Intro title text section */}
        <Section>
          <div style={{ textAlign: 'center'}}>
            <p style={{ fontSize: "50px"}}>Roemers Hospitality Group</p>
            <h1>Creating world-class dining & leisure experiences</h1>  
            <h2>One moment at a time</h2>
          </div>
        </Section>

        {/* Image grid section */}
        <Section >
          <div className="Grid"> 
            <div className='Grid-Square'>
              <img src={restaurant2} alt='' />
            </div>
            <div className='Grid-Square'>
              <img src={restaurant1} alt='' />
            </div>
            <div className='Grid-Square'>
              <img src={roofBar} alt='' />
            </div>
            <div className='Grid-Square'>
              <img src={restaurant3} alt='' />
            </div>
            <div className='Grid-Square'>
              <img src={restaurant4} alt='' />
            </div>
            <div className='Grid-Square'>
              <img src={restaurant5} alt='' />
            </div>
            <div className='Grid-Square'>
              <img src={restaurant6} alt='' />
            </div>
            <div className='Grid-Square'>
              <img src={restaurant7} alt='' />
            </div>
          </div>
        </Section>

        {/* Description text section */}
        <Section>
          <div style={{ textAlign: 'center'}}>
            <h2>From the second you walk in our doors, our mission is you.</h2>
            <h3>We pride ourselves on our dedication to the customer and the achievement of a once-in-a-lifetime experience every time you join us.</h3>
            <p>With expertise in hospitality, food and beverage, and event planning, our team is able to create and deliver breathtaking dining and leisure with ease and precision.</p>
          </div>
        </Section>

        {/* Spotlight section */}
        <Section>
          <div className='Section-Content-Container'>
            <div style={{ alignSelf: "center", textAlign: "center", marginRight: "25px" }}>
              <img style={{ maxHeight: '200px' }} alt="Hayley Roemers" src={hayley} />
              <h2>Hayley Roemers</h2>
              <h3>Founder & CEO</h3>
            </div>  
            <div style={{ alignSelf: "center", textAlign: "center" }}>
              <p>
                Having achieved her Bachelor's degree in Hospitality Administration & Management from California State Polytechnic University, Pomona, Hayley Roemers has dedicated herself to the art of hospitality.
                With experience running event management at Wente Vineyards, she learned the sales and event planning tenets needed to execute large and meticulously coordinated events, as well the management skills to run multiple teams simultaneously.
                Afterwards, she quickly moved up the ladder at Proper Hospitality from Food & Beverage manager of one of San Francisco's hottest rooftop bars to Assistant Manager of Outlets, before becoming the youngest General Manager of the restaurant La Bande.
                Her aspirations to run her own restaurants, bars, and hotels drive her success to achieve big, and fuel her desire to one day create something that everyone will want to be apart of.
              </p>
            </div>
          </div>
        </Section>
      </Body>
    </>
  );
}

export default HomePage;
