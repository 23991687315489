import '../../App.css';
import HeroImage from '../../components/HeroImage';
import Section from '../../components/Section';
import Body from '../Body';
import constants from '../../constants.json';

export default function Careers() {
  const heroImage = `${constants.S3_URI}assets/restaurants/wide_restaurant_interior_5.png`;

  return (
    <>
      <HeroImage imageSrc={heroImage} /> 
      <Body>
        {/* Description text section */}
        <Section>
          <h2>If making memorable experiences, running highly dynamic teams, or creating state-of-the-art brick & mortar locations interests you...</h2>
          <h3>Contact us at <span className='Text-Bold'>{constants.INFO_EMAIL_ADDRESS}</span> for information on career opportunities and potential partnerships.</h3>
        </Section>
      </Body>
    </>
  );
}