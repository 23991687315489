import '../App.css';

export default function Body({ children }) {

  return ( 
    <div className='Body-Container'>
      <div id="body" className='Body'>
        {children}
      </div>
    </div>
  )
}