import { Link } from 'react-router-dom';

export default function HeaderLink({text, link}) {

  // TODO selected link text should be bold
  
  return (
    <Link className='Header-Link' to={link} style={{ textDecoration : "none" }}>
     {text}
    </Link>
  )
}