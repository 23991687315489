import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Scrolls to the top of the page whenever the location changes
function ScrollToTop({ children} ) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
}

export default ScrollToTop;