import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Careers from './pages/Careers';
import Logos from './pages/Logos';
import Locations from './pages/Locations';
import Header from "../components/Header";
import constants from '../constants.json';
import ScrollToTop from '../hooks/scrollToTop';
import '../App.css';
import AllImageSources from '../assets/AllImageSources';
import Body from './Body';

// TODO add footer


const routing = (
  <Routes>
    <Route path={constants.HOME_PAGE_ROUTE} element={<HomePage />} />
    <Route path={constants.LOGOS_PAGE_ROUTE} element={<Logos />} />
    <Route path={constants.LOCATIONS_PAGE_ROUTE} element={<Locations />} />
    <Route path={constants.CAREERS_PAGE_ROUTE} element={<Careers />} />
  </Routes>
)

export default function App(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Load images on app start
    cacheImages(AllImageSources);
  }, [])

  async function cacheImages(srcArray) {
    const promises = await srcArray.map(src => {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = src
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);

    setLoading(false);
  }

  const appContent = loading ? (
    <>
      <Header />
      <Body />
    </>
  ) : (
    <ScrollToTop>
      <Header />
      {routing}
    </ScrollToTop>
  );

  return (
    <div className="App-Container">
      <div className="App">
        {appContent}
      </div>
    </div>
  )
}