import constants from '../../constants.json';
const rhgNoBorder = `${constants.S3_URI}assets/logos/backwards_rhg_no_border.svg`;
const rhgBorder = `${constants.S3_URI}assets/logos/backwards_rhg_white_border.svg`;
const rhSquare = `${constants.S3_URI}assets/logos/rh_square.svg`;
const rhBorder = `${constants.S3_URI}assets/logos/rh_white_border.svg`;
const rh = `${constants.S3_URI}assets/logos/rh.svg`;
const rhgText1 = `${constants.S3_URI}assets/logos/rhg_text1.svg`;
const rhgText2 = `${constants.S3_URI}assets/logos/rhg_text2.svg`;
const rhgText3 = `${constants.S3_URI}assets/logos/rhg_text3.svg`;
const rhgText4 = `${constants.S3_URI}assets/logos/rhg_cursive_text.svg`;
const rhgStacked1 = `${constants.S3_URI}assets/logos/rhg_stacked1.svg`;
const rhgStacked2 = `${constants.S3_URI}assets/logos/rhg_stacked2.svg`;
const rhgWhiteBorder1 = `${constants.S3_URI}assets/logos/rhg_white_border_variant.svg`;
const rhgWhiteBorder2 = `${constants.S3_URI}assets/logos/rhg_white_border.svg`;

export {
  rh,
  rhgNoBorder,
  rhgBorder,
  rhSquare,
  rhBorder,
  rhgText1,
  rhgText2,
  rhgText3,
  rhgText4,
  rhgStacked1,
  rhgStacked2,
  rhgWhiteBorder1,
  rhgWhiteBorder2
}